const System_Consultancies = () =>{

    function create_system(ClassItem, ClassContainer, NavOptions){
        const Itens = document.querySelectorAll(`.${ClassContainer} .${ClassItem}`);
        const NavItem = document.querySelectorAll(`.${NavOptions} .item`);
        
        
        //Aplique o X3 somente se o Swiper estiver com a opção loop ative 
        if(Itens.length == NavItem.length){
            Itens.forEach((item, key)=>{
                item.dataset.consultancieItem = `item-${key}`;
                NavItem[key].dataset.consultancieItem = `item-${key}`;
                /*NavItem[key].dataset.consultancieItem = `item-${key}`;
                NavItem[key+3].dataset.consultancieItem = `item-${key}`;
                NavItem[key+6].dataset.consultancieItem = `item-${key}`;*/
            })
        }
    }
    

    function handleClick(ClassItem, ClassContainer, NavOptions){
        const NavItem = document.querySelectorAll(`.${NavOptions} .item`);
        NavItem.forEach((Item)=>{
            Item.addEventListener("click", (e)=>{
                const ItemActive = document.querySelector(`.${NavOptions} .item.active`);
                ItemActive.classList.remove("active");

                const idConsultancie = e.target.attributes["data-consultancie-item"].value;

                const LessActiveContainerConsultancie = document.querySelector(`.${ClassContainer} .${ClassItem}.active`);
                const ActiveContainerConsultancie = document.querySelector(`.${ClassContainer} .${ClassItem}[data-consultancie-item=${idConsultancie}]`);
                
                LessActiveContainerConsultancie.classList.remove("active");
                ActiveContainerConsultancie.classList.add("active");

                /*ItemActive.forEach((ItemActive)=>{
                    ItemActive.classList.remove('active');
                })*/

                e.target.classList.add('active');
            }, false);
        })
    }

    function main(){
        const ClassContainer = "container-itens-consultancies";
        const ClassItem = "item-consultancie";
        const NavOptions = "nav-consultancies";

        create_system(ClassItem, ClassContainer, NavOptions);

        handleClick(ClassItem, ClassContainer, NavOptions);
    }

    main();
}
export default System_Consultancies;