const Swiper_Options_Consultancies = () =>{

    function swiper_init(classSwiper){
        let swiper = new Swiper(`.${classSwiper}`, {
            slidesPerView: 1.4,
            spaceBetween: 0,
           // centeredSlides: true,
            //loop: true,
            breakpoints: {
                500: {
                    slidesPerView: 2.4,
                },
                720: {
                  slidesPerView: 3,
                }
            },
        })
    }

    function main(){
        const classSwiper = 'swiper-nav-consultancies';
        swiper_init(classSwiper);
    }


    main();
}

export default Swiper_Options_Consultancies;