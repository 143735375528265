const AnimationLoadingBalls = () =>{

    function addPin(single_ball){
        single_ball.classList.add("pin");
    }

    function reset(balls){
        if(balls){
            balls.forEach((ball)=>{
                ball.classList.remove("pin");
                ball.classList.remove("stage-1");
                ball.classList.remove("stage-2");
            })
        }
    }

    function updatePin(pin, limit){
        pin+=1;

        if(pin > limit){
            pin = 1;
        }
        return(pin);
    }
    
    function update(balls, limit, pin){

        if(pin <= 2){
            if(pin == 1){
                balls[(pin+1)-1].classList.add("stage-2");
                balls[(pin+2)-1].classList.add("stage-1");
            }
            else{
                balls[(pin-1)-1].classList.add("stage-2");

                balls[(pin+1)-1].classList.add("stage-2");
                balls[(pin+2)-1].classList.add("stage-1");
            }
        }
        else if(pin >= limit - 2){
            if(pin == limit-1){
                balls[(pin+1)-1].classList.add("stage-2");

                balls[(pin-1)-1].classList.add("stage-2");
                balls[(pin-2)-1].classList.add("stage-1");
            }
            else{
                balls[(pin-1)-1].classList.add("stage-2");
                balls[(pin-2)-1].classList.add("stage-1");
            }
        }
        else if(pin > 2 && pin < limit - 2){
            balls[(pin-1)-1].classList.add("stage-2");
            balls[(pin-2)-1].classList.add("stage-1");

            balls[(pin+1)-1].classList.add("stage-2");
            balls[(pin+2)-1].classList.add("stage-1");
        }
    }

    function main(){
        let limit = 7;
        let pin = 4;
        const idGroupBalls = "group-balls-1";

        const balls = document.querySelectorAll(`#${idGroupBalls} span`);
        //pin = updatePin(pin, limit);

        //animation
        setInterval(()=>{
            reset(balls);
            pin = updatePin(pin, limit);
            addPin(balls[pin-1])
            update(balls, limit, pin);
        }, 300);
        
        

    }
    main();
}

export default AnimationLoadingBalls;