const Button_Hamburge = () =>{


    function main(){
        const idMenuPrincipal = 'bt-hamburgue';
        const classItensMenus = 'itens-menu-principal';

        const MenuPrincipal = document.querySelector(`#${idMenuPrincipal}`);
        const ItensMenus = document.querySelector(`.${classItensMenus}`);
        if(MenuPrincipal){
            MenuPrincipal.addEventListener("click", (e)=>{
                if(e.target.classList.contains('active')){
                    
                    e.target.classList.remove('active');
                    ItensMenus.classList.remove('active');
                }
                else{
                    e.target.classList.add('active');
                    ItensMenus.classList.add('active');
                }
            });

        }

        const OptionMenu = document.querySelectorAll(`.${classItensMenus} li a`);
        if(OptionMenu){
            OptionMenu.forEach((item_nav)=>{
                item_nav.addEventListener("click", ()=>{
                    
                    MenuPrincipal.classList.remove('active');
                    ItensMenus.classList.remove('active');
                    
                });
            })
        }
    }

    main();
}

export default Button_Hamburge;