const Swiper_Bar = () =>{

    function swiper_init(classSwiper){
        let swiper = new Swiper(`.${classSwiper}`, {
            slidesPerView: "auto",
            spaceBetween: 0,
            autoplay:{
                delay: 0,
            },
            speed: 1500,
            loop: true,
            allowTouchMove: false, 
            /*breakpoints: {
                640: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 5,
                },
                1440: {
                    slidesPerView: 8,
                }
            },*/

        })
    }

    function main(){
        const classSwiper = 'swiper-bar';
        swiper_init(classSwiper);
    }

    main();
}

export default Swiper_Bar;