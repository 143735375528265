const Form_Contact = () =>{


    /**
     * 
     * {*} [value_form] É o valor atual que o input possui 
     * {*} [label_status] Verifica se um determinado input foi preenchido ou não. Caso sim ele ficará acima do campo de texto.
     * {*} [messageErro] Remove a mensagem de error assim que for preenchido 
     *  {*} [barErro] Remove a mensagem de error assim que for preenchido  
     */
    function is_completed_field_form(value_form, label_status, messageErro, barErro){

        if(value_form == ''){
            label_status.classList.remove("is_completed");
            
        }
        else{
            label_status.classList.add("is_completed");
            messageErro.classList.remove('active');
            barErro.classList.remove('is_required_not');

        }
        
    }

    function is_field_required(value_form, content_bar, MessageErro, Form){
        if(value_form == ''){
            content_bar.classList.add("is_required_not");
            MessageErro.classList.add("active");
            Form.classList.add('erro');
            setTimeout(()=>{
                Form.classList.remove('erro');  
            }, 1500);

            return 1;
        }
        else{
            content_bar.classList.remove("is_required_not");
            MessageErro.classList.remove("active");
            Form.classList.remove('erro');

            return 0;
        }
    }

    function disabledBtn(Bt_Submit) {
        Bt_Submit.disabled = true
        Bt_Submit.classList.add("disabled")
    }

    function enabledBtn(Bt_Submit) {
        Bt_Submit.disabled = false
        Bt_Submit.classList.remove("disabled")
    }

    function showMessage(response, Form, MessageSuccess) {
       /* const message = response.data ? response.data.message : response.message
        GLOBAL.showToastify(message)*/

        if(response.data){
            MessageSuccess.classList.add('active');

            setTimeout(()=>{
                MessageSuccess.classList.remove('active');
            }, 2500);
        }
        Form.reset()
        setTimeout(enabledBtn, 1000)
    }


    function main(){

        //Classes & Ids Globais em relação a função main.
        const idForm = "form-how-can-we-help";
        const classInputs = "input-form";
        const classMessageSuccess = "form-contact-card-sucess";
        const classBtClosedMessageSuccess = 'bt-closed-message-card-sucess';


        const Form = document.querySelector(`#${idForm}`);
        const Inputs_Required = document.querySelectorAll(`#${idForm} .${classInputs}[required='true']`);
        const MessageSuccess = document.querySelector(`.${classMessageSuccess}`);
        const BtClosedMessageSuccess = document.querySelector(`.${classBtClosedMessageSuccess}`);
    
        //Selecionando os inputs do formulário
        const Inputs = document.querySelectorAll(`#${idForm} .${classInputs}`);
        if(Inputs){
            Inputs.forEach((Input)=>{

                //Aplicando um evento de mudança sempre que determinado input sofrer alterações.
                Input.addEventListener("change", (e)=>{

                   
                    const label = e.target.previousElementSibling;
                    const barErro = e.target.nextElementSibling;
                    const messageErro = e.target.nextElementSibling.nextElementSibling;
                    
                    is_completed_field_form(e.target.value, label, messageErro, barErro);
                }, false);
            });
        }

        const Bt_Submit = document.querySelector(`#${idForm} button[type='submit']`);
        if(Bt_Submit){
            Bt_Submit.addEventListener("click", (e)=>{
                e.preventDefault();
                
                let error = 0;

                Inputs_Required.forEach((Input)=>{
                    const bottomElement = Input.nextElementSibling;
                    const MessageErro = bottomElement.nextElementSibling;
                    
                    error += is_field_required(Input.value, bottomElement, MessageErro, Form);
                 

                });



                if(error == 0){

                    
                    let yourHeight = 360;
                    // scroll to your element
                    Form.scrollIntoView(true);

                    // now account for fixed header
                    let scrolledY = window.scrollY;

                    if(scrolledY && window.innerWidth < 1440){
                        window.scroll(0, scrolledY - yourHeight);
                    }
                    
                    //window.scrollTo({ top: , behavior: 'smooth' });
                    disabledBtn(Bt_Submit);
                        const form_data = {
                            nome: document.querySelector(`#${idForm} input[name=help-name]`).value,
                            email: document.querySelector(`#${idForm} input[name=help-email]`).value,
                            telefone: document.querySelector(`#${idForm} input[name=help-telephone]`).value,
                            mensagem: document.querySelector(`#${idForm} textarea[name=help-message]`).value
                        }
                        //console.log('hdhs');
                        //console.log(form_data);
                        const url = "/fale-conosco/"
                        axios.post(url, form_data)
                            .then((response) => showMessage(response, Form, MessageSuccess))
                            .catch((response) => showMessage(response, Form, MessageSuccess));
                   
                }
                
                
            })
        }


        if(BtClosedMessageSuccess){
            BtClosedMessageSuccess.addEventListener("click", ()=>{
                MessageSuccess.classList.remove('active');
            })
        }



    }

    main();
}

export default Form_Contact;