// PAGES
const pageHome = document.querySelector("#page__home");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";

mostrarSenha()
showFormErrors()

// SECTIONS
/*import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
import salebrandSobre from "./salebrand/salebrandSobre.js";
import salebrandClientes from "./salebrand/salebrandClientes.js";
import salebrandContato from "./salebrand/salebrandContato.js";

salebrandPortfolio();
salebrandSobre();
salebrandClientes();
salebrandContato();*/

// SWIPERS
import Swiper_Bar from "./laguna_cp/swiper/swiper-bar.js";
import Swiper_Options_Consultancies from "./laguna_cp/swiper/swiper-options-consultancies.js";


//SYTEM
import System_Consultancies from "./laguna_cp/system/system-consultancies.js";
import Form_Contact from "./laguna_cp/system/form-contact.js";

import Tooltip_Graphi from "./laguna_cp/chartist/chartist-tooltip.js";
import Tooltip_Graphi_Area_Show from "./laguna_cp/chartist/chartist-tooltip-area-show.js";

import Button_Hamburge from "./laguna_cp/system/button-hamburge.js";

import ModalInDevelopment from "./laguna_cp/system/modal-in-development.js";

import AnimationLoadingBalls from "./laguna_cp/animation/animation-loading-balls.js";

// ★ Pages Scopo
if (pageHome) {
  document.body.classList.add("page__home");

  Swiper_Bar();
  Swiper_Options_Consultancies();
  System_Consultancies();
  Form_Contact();
  Tooltip_Graphi();
  Tooltip_Graphi_Area_Show();
  Button_Hamburge();
  AnimationLoadingBalls();
  ModalInDevelopment();
}

window.addEventListener("scroll", function () {
  headerEl.classList.toggle("sticky", window.scrollY > 150);
});

const headerEl = document.getElementById('menu-principal');
document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);
