const ModalInDevelopment = () =>{

    function main(){
        const idModal = "modal-in-development";

        const Modal = document.querySelector(`#${idModal}`);
        const btClosed = document.querySelectorAll(`#closed-${idModal}`);
        const btOpen = document.querySelectorAll(`#open-${idModal}`);

        if(btClosed){
            btClosed.forEach((closed)=>{
                closed.addEventListener("click", ()=>{
                    Modal.classList.remove("open");
                });
            })
        }

        if(btOpen){
            btOpen.forEach((open)=>{
                open.addEventListener("click", ()=>{
                    Modal.classList.add("open");
                })
            })
        }

        document.addEventListener("click", (e)=>{
            console.log(e.target);
            if(e.target == Modal){
                Modal.classList.remove("open");
            }
        });

    }
    main();
}

export default ModalInDevelopment;