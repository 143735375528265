
const Tooltip_Graphi = () =>{
    
  function sort_values(n){
    const new_value = new Array();

    for(let i = 0; i<parseInt(n); i++){
      const value = Math.floor(Math.random() * (n - 0) + 0);
      new_value.push(
        {
          meta: `${100*value/parseInt(n)}%`,
          value: value
        }
      )
    }

    return (new_value);
  }


    function update_chartist(chart, new_values){
      chart.update({
          labels: [1, 2, 3, 4, 5],
          series:[
            new_values
          ]
        });
    }

    function init_chartist(classChartist){
      
        let chart = new Chartist.Line(`.${classChartist}`, {
            labels: [1, 2, 3, 4, 5],
            series: [
              [
                {meta: '70%', value: 2.5 },
                {meta: '60%', value: 2 },
                {meta: '70%', value: 2.5},
                {meta: '45%', value: 1.2},
                {meta: '30%', value: 0.9}
              ]
            ]
          }, {
            low: 0,
            high: 4,
            fullWidth: true,
            
            axisY: {
              onlyInteger: true,
              offset: 20
            },
            plugins: [
              Chartist.plugins.tooltip()
            ]
          });

         return (chart);
        
        
          
    }

    function main(){
        const classChartist = 'tooltip-graphi';
        const Chartist = document.querySelectorAll(`.${classChartist}`);
        const charts = new Array();

        Chartist.forEach((item, key)=>{
          item.classList.add(`tooltip-key-${key}`);
          charts.push(init_chartist(`tooltip-key-${key}`));
        })

       // const charts = init_chartist('tooltip-graphi');

        interval = setInterval(()=>{
          let k = sort_values(5);
          charts.forEach((chart)=>{
            update_chartist(chart, k);
          })
          //update_chartist(charts, k);
        }, 1000);
       // animation_chartist(charts);
    }

    main();
}
export default Tooltip_Graphi;