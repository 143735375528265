
const Tooltip_Graphi_Area_Show = () =>{

    function init_chartist(){
        var chart = new Chartist.Line('.tooltip-graphi-area-show', {
            labels: [1, 2, 3, 4, 5, 6, 7],
            series: [
              [
                {meta: '20%', value: 1 },
                {meta: '24%', value: 1.3 },
                {meta: '75%', value: 3.5},
                {meta: '22%', value: 1.2},
                {meta: '30%', value: 2.0},
                {meta: '10%', value: 0.7},
                {meta: '30%', value: 2.0},
              ]
            ]
          }, {
            low: 0,
            high: 5,
            fullWidth: true,
            showArea: true,
            plugins: [
              Chartist.plugins.tooltip()
            ]
          });
          
    }

    function main(){
        init_chartist();
    }

    main();
}
export default Tooltip_Graphi_Area_Show;